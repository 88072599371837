







































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'

@Component
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() picklId!: any

  deletePickl () {
    this.$http
      .delete('pickls/' + this.picklId, {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        let DeletePicklCloseButtonPopup: HTMLElement = this.$refs.DeletePicklCloseButtonPopup as HTMLElement
        this.$notify({ type: 'success', text: 'Pickl has been deleted successfully!' })
        this.$emit('picklDeleted', this.picklId)
        DeletePicklCloseButtonPopup.click()
      })
  }
}
